<script setup>
import sanitizer from '@/util/htmlSanitizer'
import ProfileVue from '@/components/Labs/Profile.vue'
import AchievementsVue from '@/components/Labs/Achievements.vue'
import LoadingVue from '@/components/ui/Loading.vue'
import { getLab, getLabCategory } from '@/api/labs'

import LanguageConverter from '@/util/LanguageConverter'

import { ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const labNo = route.params.id
const labData = ref(null)
const techOrganizationData = ref(null)
const isLoading = ref(false)

const splitBy = (str, sign) => {
  if (!str) return ''
  if (!str.includes(sign)) return `<span class="splited-str">${str}</span>`

  return str.split(sign).reduce((acc, cur) => {
    return cur ? acc + `<span class="splited-str">${cur}</span>` : acc
  }, '')
}

onMounted(async () => {
  isLoading.value = true
  const data = await getLab(labNo)

  if (data.organizationExist) {
    techOrganizationData.value = {
      organizationCooperation: splitBy(LanguageConverter({ en: data.organizationCooperationEn, kr: data.organizationCooperation }), '$'),
      organizationDescription: splitBy(LanguageConverter({ en: data.organizationDescriptionEn, kr: data.organizationDescription }), '$'),
      organizationTechHandover: splitBy(LanguageConverter({ en: data.organizationTechHandoverEn, kr: data.organizationTechHandover }), '$')
    }
  }

  if (data.code === 'DB_DATA_NOT_FOUND') {
    router.go(-1)
    return alert('연구실 정보가 없습니다.')
  }
  const category = (await getLabCategory(data.labScientificCategoryId)).labScientificCategoryName

  labData.value = {
    ...data,
    category
  }

  isLoading.value = false
})
</script>

<template>
  <LoadingVue v-if="isLoading" />

  <section class="wf__lab-section" v-if="labData">
    <article class="wf__lab-section-head">
      <div class="wf__lab-section-head-wrapper">
        <h5 class="wf__lab-section-head-subtitle">{{ labData.category }}</h5>
        <h4 class="wf__lab-section-head-title">{{ LanguageConverter({ en: labData.labNameEn, kr: labData.labName }) }}</h4>
        <p
          class="wf__lab-section-head-description"
          v-html="sanitizer(LanguageConverter({ en: labData.introductionEn, kr: labData.introduction })?.split('\n').join('<br />'))"
        ></p>
      </div>
    </article>

    <article class="wf__lab-section-profile">
      <ProfileVue :labData="labData" />
    </article>

    <article class="wf__lab-section-achieve">
      <AchievementsVue :labNo="labNo" :techOrganizationData="techOrganizationData" />
    </article>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.wf__lab-section {
  display: flex;
  flex-direction: column;
  gap: 4em;
}

.wf__lab-section-head {
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 5em;
  text-align: center;
}
.wf__lab-section-head-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  max-width: 70%;
}
.wf__lab-section-head-subtitle {
  font-size: var(--wf-text-20);
  color: var(--wf-gray-color);
}
.wf__lab-section-head-title {
  word-break: normal;
  font-size: var(--wf-text-40);
  font-weight: bold;
}
.wf__lab-section-head-description {
  margin-top: 1em;
  text-align: left;
  font-size: var(--wf-text-16);
  color: var(--wf-secondary-color);
}
</style>
