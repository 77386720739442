<script>
import LanguageConverter from '@/util/LanguageConverter'
import sanitizer from '@/util/htmlSanitizer'

export default {
  components: {},
  props: {
    techOrganizationData: {
      type: Object
    }
  },
  data() {
    return {}
  },
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    sanitizer,
    LanguageConverter
  }
}
</script>

<template>
  <div class="wf__tech-oraganization">
    <div
      class="wf__tech-oraganization-head"
      v-html="sanitizer(LanguageConverter({ en: 'Organization Description', kr: '연구실 조직 세부사항' }))"
    ></div>
    <div class="wf__tech-oraganization-body" v-html="sanitizer(this.techOrganizationData.organizationDescription)"></div>

    <div
      class="wf__tech-oraganization-head"
      v-html="sanitizer(LanguageConverter({ en: 'TechHandover History', kr: '기업협력 경험' }))"
    ></div>
    <div class="wf__tech-oraganization-body" v-html="sanitizer(this.techOrganizationData.organizationTechHandover)"></div>

    <div
      class="wf__tech-oraganization-head"
      v-html="sanitizer(LanguageConverter({ en: 'Organization Cooperation', kr: '협력파트너쉽 현황' }))"
    ></div>
    <div class="wf__tech-oraganization-body" v-html="sanitizer(this.techOrganizationData.organizationCooperation)"></div>
  </div>
</template>

<style scoped>
.wf__tech-oraganization {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1em;
  row-gap: 1em;

  padding: 1em;
  font-size: var(--wf-text-16);
}
.wf__tech-oraganization-head {
  display: flex;
  justify-content: space-between;
  padding-top: 0.5em;
  line-height: 1em;
}
.wf__tech-oraganization-head::after {
  content: '';
  display: block;
  width: 1px;
  background-color: var(--wf-secondary-color);
  height: 1em;

  margin-left: 1em;
}

.wf__tech-oraganization-body {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
}

:deep(.splited-str) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  background-color: var(--wf-gray-600);
  text-align: center;
  border-radius: 7px;
}

@media screen and (max-width: 576px) {
  .wf__tech-oraganization {
    grid-template-columns: 1fr;
  }

  .wf__tech-oraganization-head::after {
    display: none;
  }
}
</style>
