//INFO: 화면설명
<script>
import sanitizer from '@/util/htmlSanitizer'

export default {
  name: '',
  components: {},
  data() {
    return {}
  },
  props: {
    boardList: Array,
    disableLink: {
      type: Boolean,
      default: false
    }
  },
  emits: ['boardListItemClick'],
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    sanitizer,
    listItemClickHandler(e) {
      this.$emit('boardListItemClick', e)

      if (this.disableLink) return
      const target = e.target.matches('li') ? e.target : e.target.closest('li')
      this.$router.push(target.dataset.link)
    }
  }
}
</script>

<template>
  <ul v-if="boardList.length !== 0" class="wf__board-list">
    <li v-for="(item, i) in boardList" :key="i" :data-link="item.link" @click="listItemClickHandler">
      <div class="wf__board-list-item" v-if="item.textContents">
        <span>
          {{ item.textContents.title }}
          <div class="wf__board-list-subtitle-item">
            <span v-for="(sub, si) in item.textContents.subtitle" v-show="sub" :key="si">{{ sub }}</span>
          </div>
        </span>

        <span class="wf__board-list-lab-item">
          {{ item.textContents.lab }}
        </span>
      </div>
      <span v-else v-for="(text, j) in item.textList" :key="j" v-html="sanitizer(text)"></span>
    </li>
  </ul>
</template>

<style scoped>
.wf__board-list {
  margin-top: 0.5em;
  font-size: var(--wf-text-16);
}

.wf__board-list li {
  padding: 0.25em 0;
  margin: 0.25em 0;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  cursor: pointer;
}
.wf__board-list li span {
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wf__board-list li > span:first-child {
  flex: 1;
  max-width: calc(100% - 12em);
}

.wf__board-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wf__board-list-subtitle-item {
  display: flex;
  font-size: var(--wf-text-14);
  color: var(--wf-gray-color);
  flex-wrap: wrap;
}

.wf__board-list-subtitle-item > span:not(:last-child)::after {
  content: '/';
  padding: 0.5em;
}
.wf__board-list-subtitle-item > span:last-child {
  padding-right: 1px;
}

.wf__board-list-lab-item {
  font-size: var(--wf-text-14);
  color: var(--wf-gray-color);
}

/* 모바일 */
@media screen and (max-width: 992px) {
  .wf__board-list li > span:last-child {
    max-width: 10em;
  }

  .wf__board-list-lab-item {
    display: none;
  }
}
</style>
