<script setup>
import { ref, defineProps, onMounted, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import LanguageConverter from '@/util/LanguageConverter'

const props = defineProps({
  menuItemList: Array,
  loadMore: {
    default: true,
    type: Boolean
  }
})
const emit = defineEmits(['onItemClick'])
const router = useRouter()
const link = ref('')

const menuItemClickHandler = (e) => {
  const menuItem = e.srcElement
  const menuWrapper = menuItem.parentElement

  menuWrapper.querySelector('.active').classList.remove('active')
  menuItem.classList.add('active')

  link.value = menuItem.dataset.link
  emit('onItemClick', menuItem)
}

const loadMoreClickHandler = () => {
  router.push(link.value)
}

onMounted(() => {
  link.value = props.menuItemList[0].link
})
</script>

<template>
  <div class="navigation">
    <ul class="nav-menu-wrapper">
      <li
        v-for="(item, i) in menuItemList"
        :key="i"
        class="nav-menu-item"
        :class="i === 0 ? 'active' : ''"
        :data-link="item.link"
        @click="menuItemClickHandler"
      >
        {{ item.text }}
      </li>
    </ul>

    <span v-if="loadMore" class="load-more" @click="loadMoreClickHandler"> + {{ LanguageConverter({ en: 'MORE', kr: '더보기' }) }} </span>
  </div>

  <div class="divider"></div>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: var(--wf-text-16);
}
.divider {
  width: 100%;
  height: 2px;
  background-color: var(--wf-gray-color);
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 1em;

  flex-wrap: wrap;
}
.navigation .nav-menu-wrapper {
  display: flex;
  gap: 1em;

  flex-wrap: wrap;
}
.navigation .nav-menu-wrapper .nav-menu-item {
  position: relative;
  padding: 0.5em;
  font-weight: 600;
  cursor: pointer;
  color: var(--wf-secondary-color);
  font-size: var(--wf-text-18);
}
.navigation .nav-menu-wrapper .nav-menu-item.active {
  color: var(--wf-primary-color);
}
.navigation .nav-menu-wrapper .nav-menu-item.active::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: var(--wf-primary-color);
}
.navigation .load-more {
  display: flex;
  align-items: flex-end;
  padding: 0.5em;
  font-size: 14px;
  color: var(--wf-gray-color);

  cursor: pointer;
}
</style>
