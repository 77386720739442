<script>
import BoardsNavigationVue from '@/components/Home/BoardsNavigation.vue'
import PlatformBoardListVue from '@/components/ui/PlatformBoardList.vue'
import PaginationBarVue from '@/components/ui/PaginationBar.vue'
import LoadingVue from '@/components/ui/Loading.vue'
import TechTransferCapabilitiesVue from '@/components/Labs/TechTransferCapabilities.vue'
import { getLabPaper, getLabPatent, getLabPjt, getTechHandover } from '@/api/platform/community'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { BoardsNavigationVue, PlatformBoardListVue, LoadingVue, PaginationBarVue, TechTransferCapabilitiesVue },
  props: ['labNo', 'techOrganizationData'],
  data() {
    const achievementsList = [
      { link: '/community/pjt', text: LanguageConverter({ en: 'Projects', kr: '프로젝트' }) },
      { link: '/community/paper', text: LanguageConverter({ en: 'Papers', kr: '논문' }) },
      { link: '/community/patent', text: LanguageConverter({ en: 'Patents', kr: '지재권' }) },
      { link: '/community/handover', text: LanguageConverter({ en: 'Tech Cooperations', kr: '기업협력' }) }
    ]

    if (this.techOrganizationData) {
      achievementsList.splice(1, 0, { link: 'TTC', text: LanguageConverter({ en: 'Lab Capabilities', kr: '연구실 고유 역량' }) })
    }

    const boardTypes = ['pjt', 'paper', 'patent', 'handover']
    const boardApis = {
      pjt: getLabPjt,
      paper: getLabPaper,
      patent: getLabPatent,
      handover: getTechHandover
    }

    const mapPjtData = (item) => {
      return {
        link: `/community/pjt/${item.projectId}`,
        textContents: {
          title: LanguageConverter({ en: item.projectTitleEn, kr: item.projectTitleKr }),
          subtitle: [
            `(${item.projectNumber})`,
            `${item.projectYear ? new Date(item.projectYear).getFullYear() : ''}`,
            item.projectResearchAgency,
            item.projectMinistry
          ],
          lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
        }
      }
    }
    const mapPaperData = (item) => {
      return {
        link: `/community/paper/${item.paperId}`,
        textContents: {
          title: item.paperResultTitle,
          subtitle: [
            `(${item.paperResultID})`, //
            `${item.paperPubYear ? new Date(item.paperPubYear).getFullYear() : ''}`,
            item.paperJCType,
            item.paperJournalName
          ],
          lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
        }
      }
    }
    const mapPatentData = (item) => {
      return {
        link: `/community/patent/${item.patentId}`,
        textContents: {
          title: item.patentResultTitle,
          subtitle: [
            `(${item.patentResultID})`, //
            `${item.patentYear ? new Date(item.patentYear).getFullYear() : ''}`,
            item.patentRegistType,
            item.patentIprType,
            item.patentRegistrant
          ],
          lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
        }
      }
    }
    const mapHandoverData = (item) => {
      return {
        link: `/community/handover/${item.handoverId}`,
        textContents: {
          title: LanguageConverter({ en: item.techNameEn, kr: item.techName }),
          subtitle: [
            `${item.techTransferYear ? new Date(item.techTransferYear).getFullYear() : ''}`, //
            item.techGrowLevel,
            item.techStatus,
            item.techApplyField
          ],
          lab: LanguageConverter({ en: item.labNameEn, kr: item.labName })
        }
      }
    }
    const mapBoardFuncs = {
      pjt: mapPjtData,
      paper: mapPaperData,
      patent: mapPatentData,
      handover: mapHandoverData
    }

    return {
      showTTCComponent: false,
      achievementsList, // BoardsNavigationVue의 props
      boardApis,
      mapBoardFuncs,
      boardType: boardTypes[0], // 조회하려는 게시판 종류
      boardList: [], // 조회한 게시글
      achievementsMap: {},
      achievements: {},
      isLoading: false,
      dataCount: 0,
      boardLimits: 10
    }
  },
  methods: {
    getTitleKey(boardType) {
      return this.titleMap[boardType]
    },

    async loadData(offset = 0) {
      this.isLoading = true

      const result = await this.boardApis[this.boardType]({ labNo: this.labNo, limit: this.boardLimits, offset })

      if (result.code === 'DB_DATA_NOT_FOUND') {
        this.dataCount = 0
        this.boardList = []
        return (this.isLoading = false)
      }
      if (result.code) return alert(result.message)

      this.dataCount = result.count

      this.boardList = result.items.map(this.mapBoardFuncs[this.boardType])

      this.isLoading = false
    },

    async paginationClickHandler({ itemIdx }) {
      const offset = (itemIdx - 1) * this.boardLimits
      await this.loadData(offset)
    },

    showTTC() {
      this.boardList = []
      this.showTTCComponent = true
    },
    hideTTC() {
      this.showTTCComponent = false
    },

    async changeBoard(item) {
      this.hideTTC()
      const link = item.dataset.link

      if (link === 'TTC') return this.showTTC()

      const key = link.slice(link.lastIndexOf('/') + 1, link.length)

      this.boardType = key
      await this.loadData()
    }
  },
  async mounted() {
    await this.loadData()
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading" />

  <article class="wf__board-wrapper">
    <BoardsNavigationVue :menuItemList="achievementsList" :loadMore="false" @onItemClick="changeBoard" />

    <div class="wf__board">
      <div v-if="boardList.length === 0 && !showTTCComponent">No Data</div>
      <PlatformBoardListVue v-if="boardList.length !== 0" :boardList="boardList" />
      <TechTransferCapabilitiesVue v-if="showTTCComponent" :techOrganizationData="techOrganizationData" />
    </div>
    <PaginationBarVue
      v-if="dataCount !== 0 && !showTTCComponent"
      :totalItems="dataCount"
      :itemsPerPage="boardLimits"
      @onItemClick="paginationClickHandler"
    />
  </article>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
}
.info-board-wrapper {
  margin-top: 2em;
  min-height: 20em;
}
.info-board-wrapper li {
  padding: 0.25rem 0.5rem;
  display: flex;
}
.info-board-wrapper li span:nth-child(1) {
  flex: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-board-wrapper li span:nth-child(2) {
  text-align: right;
  flex: 2;
}

.wf__board-wrapper {
  min-height: 500px;
}
.wf__board {
  /* min-height: 356px; */
  margin-bottom: 3rem;
}
</style>
