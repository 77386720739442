<script>
import ModalVue from '@/components/ui/Modal.vue'
import HandoverVue from '@/components/Tech/Handover.vue'
import Profile from '@/assets/images/profile.jpg'
import Logo from '@/assets/svgs/DefaultLogo.vue'
import { createObjectURL } from '@/api/fileAPI'

import LanguageConverter from '@/util/LanguageConverter'
import { getSixTechnology } from '@/api/category/sixTechnologyCategory'

export default {
  components: {
    ModalVue,
    HandoverVue,
    Logo
  },
  props: ['labData'],
  data() {
    const labId = this.$route.params.id

    return {
      labId,
      keywords: [],
      labLogo: '',
      researcherProfile: '',
      sixTechnologyMedium: '',
      isShowModal: false,
      showDefaultLogo: false
    }
  },
  methods: {
    LanguageConverter,
    showModal() {
      this.isShowModal = true
    },
    closeModal() {
      this.isShowModal = false
    },
    replaceByDefaultProfile(e) {
      e.target.src = Profile
    },
    replaceByDefaultLogo(e) {
      e.target.style.display = 'none'
      this.showDefaultLogo = true
    },
    async replaceImgSrc(imgUrl) {
      if (!imgUrl) return
      const splitedImgUrl = imgUrl?.split('/')

      if (splitedImgUrl[0] !== 'https:') return 'default'

      const imageName = decodeURIComponent(splitedImgUrl.at(-1))
      const childFolder = decodeURIComponent(splitedImgUrl.at(-3))
      const parentFolder = decodeURIComponent(splitedImgUrl.at(-4))

      return await createObjectURL(imageName, parentFolder, childFolder)
    },
    onLoadLogo(e) {
      e.target.style.display = 'inline-block'
    },
    setKeywords(str) {
      this.keywords = str?.split(', ') ?? []
    },
    goSearch(keyword) {
      this.$router.push(`/integratedSearch/integratedSearchResult?q=${keyword}&opt=keyword`)
    }
  },
  async mounted() {
    const cates = await getSixTechnology()
    const cate = cates.filter((item) => item.sixTechnologyId === this.labData.sixTechnologyId)[0]
    this.sixTechnologyMedium = cate.sixTechnologyMedium

    const { leadResearcherImgUrl, logoImgUrl, keyword, keywordEn } = this.labData
    this.setKeywords(this.$store.getters.isEng ? keywordEn : keyword)

    this.researcherProfile = (await this.replaceImgSrc(leadResearcherImgUrl)) || '/'
    this.labLogo = await this.replaceImgSrc(logoImgUrl)
    if (this.labLogo) {
      this.showDefaultLogo = false
    }
  }
}
</script>

<template>
  <div class="wf__profile">
    <div class="wf__profile-img-wrapper">
      <img class="wf__profile-img" :src="researcherProfile" alt="Profile" @error="replaceByDefaultProfile" />

      <div class="wf__profile-head--mobile">
        <span>
          <img class="wf__profile-logo" :src="labLogo" alt="Logo" @error="replaceByDefaultLogo" />
          <Logo class="wf__profile-logo" v-if="showDefaultLogo"></Logo>
        </span>
        <span class="text-right">
          <h5 class="wf__profile-subtitle">profile</h5>
          <h4 class="wf__profile-title">{{ LanguageConverter({ en: labData.leadResearcherNameEn, kr: labData.leadResearcherName }) }}</h4>
        </span>
      </div>
    </div>

    <div class="wf__profile-caption">
      <div class="wf__profile-head">
        <span>
          <h5 class="wf__profile-subtitle">profile</h5>
          <h4 class="wf__profile-title">{{ LanguageConverter({ en: labData.leadResearcherNameEn, kr: labData.leadResearcherName }) }}</h4>
        </span>

        <span>
          <img class="wf__profile-logo" :src="labLogo" alt="Logo" @error="replaceByDefaultLogo" @load="onLoadLogo" />
          <Logo v-if="showDefaultLogo"></Logo>
        </span>
      </div>

      <div class="divider"></div>

      <div class="wf__profile-body">
        <div class="wf__profile-body-left">
          <div class="wf__profile-body-grid">
            <div class="wf__profile-body-head">{{ LanguageConverter({ en: 'Department', kr: '학과' }) }}</div>
            <p class="wf__profile-body-desc">{{ labData.departmentName }}</p>
            <div class="wf__profile-body-head">{{ LanguageConverter({ en: 'Field', kr: '연구분야' }) }}</div>
            <p class="wf__profile-body-desc">{{ labData.category }}</p>
            <div class="wf__profile-body-head">{{ LanguageConverter({ en: 'Position', kr: '직위' }) }}</div>
            <p class="wf__profile-body-desc">{{ labData.leadResearcherPosition }}</p>
            <div class="wf__profile-body-head">{{ LanguageConverter({ en: '6T Category', kr: '6T 분류' }) }}</div>
            <p class="wf__profile-body-desc">{{ sixTechnologyMedium }}</p>

            <div class="wf__profile-body-head">{{ LanguageConverter({ en: 'Address', kr: '주소' }) }}</div>
            <p class="wf__profile-body-desc">
              {{
                LanguageConverter({
                  en: `${labData.addressMainEn} ${labData.addressDetailEn}`,
                  kr: `${labData.addressMain} ${labData.addressDetail}`
                })
              }}
            </p>

            <div v-if="labData.labHomeUrl" class="wf__profile-body-head">{{ LanguageConverter({ en: 'E-Mail', kr: '개별홈페이지' }) }}</div>
            <p v-if="labData.labHomeUrl" class="wf__profile-body-desc">
              <a class="wf__profile-home-link" :href="labData.labHomeUrl" target="_blank">
                {{ LanguageConverter({ en: 'Contact Lab', kr: labData.labHomeUrl }) }}
              </a>
            </p>

            <div></div>
            <!-- <div class="wf__profile-body-head">{{ LanguageConverter({ en: 'E-Mail', kr: '이메일' }) }}</div> -->
            <p class="wf__profile-body-desc">
              <!-- {{ labData.email }} -->
              <a class="e-btn wf__btn btn-primary" href="#" @click="showModal">
                {{ LanguageConverter({ en: 'Contact Lab', kr: '연구실협력신청' }) }}
              </a>
            </p>
          </div>
        </div>

        <div class="wf__profile-body-right">
          <h4 class="wf__profile-keyword-text">Keyword</h4>

          <div class="wf__profile-keyword-wrapper">
            <div class="wf__profile-keyword-item cursor-pointer" v-for="(keyword, i) in keywords" :key="i" @click="goSearch(keyword)">
              {{ keyword }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalVue v-if="isShowModal" @closeModal="closeModal">
    <HandoverVue :email="labData.email" :labNo="labData.labNo"></HandoverVue>
  </ModalVue>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.divider {
  width: 100%;
  height: 2px;
  background-color: var(--wf-gray-color);
  margin: 0.5em 0;
}
.wf__profile {
  display: flex;
  gap: 1.5em;
  text-align: left;

  align-items: center;

  margin: 0 2em;
}
.wf__profile,
.wf__profile a {
  font-size: var(--wf-text-16);
}
.wf__profile-img {
  width: 200px;
  height: 230px;

  object-fit: cover;
  object-position: center;
}
.wf__profile-img-wrapper {
  display: flex;
  justify-content: space-between;
}
.wf__profile-caption {
  flex: 1;
}
.wf__profile-head {
  display: flex;
  justify-content: space-between;
}
.wf__profile-title {
  font-size: var(--wf-text-24);
  font-weight: 700;
}
.wf__profile-subtitle {
  color: var(--wf-gray-color);
  font-size: var(--wf-text-16);
  text-transform: uppercase;
  margin-bottom: 0.25em;
}

.wf__profile-logo {
  max-width: 100px;
  max-height: 30px;

  object-fit: cover;
  object-position: center;
}
.wf__profile-body {
  display: flex;
  gap: 1em;
}

.wf__profile-home-link {
  color: var(--wf-primary-color);
}
.wf__profile-home-link:hover {
  opacity: 0.75;
}
.wf__profile-body > div {
  flex: 1;
}

.wf__profile-body-grid {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 0.25em;
}
.wf__profile-body-head {
  display: flex;
  justify-content: space-between;
  gap: 0.5em;
  margin-right: 0.5em;
}
.wf__profile-body-head::after {
  content: '';
  display: block;
  width: 1px;
  height: 1em;
  background-color: var(--wf-secondary-color);

  translate: 0 5px;
}

.wf__profile-keyword-text {
  font-size: 1.5em;
  font-weight: 700;
}
.wf__profile-keyword-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 0.5em;
  margin-top: 0.5em;
  margin-right: 0.5em;

  background-color: var(--wf-gray-600);
  text-align: center;

  border-radius: 7px;
}
.wf__profile-body-content-desc {
  max-width: 25em;
}

.wf__profile-head--mobile {
  display: none;
}

@media screen and (max-width: 992px) {
  .divider {
    display: none;
  }
  .wf__profile,
  .wf__profile-body {
    flex-direction: column;
  }

  .wf__profile-img {
    width: 160px;
    height: 185px;
  }

  .wf__profile-img-wrapper,
  .wf__profile-caption,
  .wf__profile-body-content-desc {
    width: 100%;
    max-width: 100%;
  }

  .wf__profile-img-wrapper {
    min-width: 100vw;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1em calc(2em + 10px);
  }
  .wf__profile-head--mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .wf__profile-head {
    display: none;
  }
}

@media screen and (max-width: 768px) {
}
</style>
